import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  takedown: {
    id: "widget.copyright-takedown-form.legal-message",
    defaultMessage:
      "<p>If you choose to request removal of content by submitting an infringement notification, please remember that you are initiating a legal process.</p><p>Do not make false claims. Misuse of this process may result in the suspension of your account or other legal consequences.</p><button>DMCA Take Down Request</button>",
  },
});

export default messages;
