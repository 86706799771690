/** @jsx jsx */
import { Button, Modal } from "@boxoffice/screenplay";
import { graphql } from "gatsby";
import React, { memo, useState } from "react";
import { FormattedMessage } from "shared/helpers/i18n/useIntl";
import { jsx, Themed } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";
import messages from "./i18n";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetCopyrightTakedownForm" }>;
}

const CopyrightTakedownFormWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <WidgetWrapper
      containerSize={containerSize}
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <FormattedMessage
        {...messages.takedown}
        values={{
          p: (message: string) => <Themed.p>{message}</Themed.p>,
          button: (message: string) => (
            <Button
              // @ts-expect-error FIXME: fix type
              variant={"buttons.primary"}
              onClick={() => setShowModal(true)}
            >
              {message}
            </Button>
          ),
        }}
      />
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <iframe
          src="https://form.jotform.com/200764101163139"
          sx={{
            border: "0",
            height: "25em",
            width: "100%",
          }}
        />
      </Modal>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetCopyrightTakedownForm on WidgetCopyrightTakedownForm {
    id
    __typename
    copyrightTakedownFormShape {
      emailAddress
    }
  }
`;

export default memo(CopyrightTakedownFormWidget);
